import SimpleLightbox from "simple-lightbox";
import Swiper from "swiper";
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import {createApp, reactive} from "petite-vue"
import tippy, {followCursor} from 'tippy.js';
import map from "./map";

import.meta.glob([ '@images/**', '!**/*.afdesign' ], { eager: true });
import.meta.env.DEV && import("@styles/main.sass");

interface Header {
    opened: boolean
    handleOpenedHeader(state: boolean): void
}

(() => {
    const headerEl = document.querySelector<HTMLElement>('[data-header]'),
        headerScope: Header = {
            opened: false,
            handleOpenedHeader(state) {
                document.body.classList.toggle("u-hiddenOverflow", state)
                hidingOnScroll()
            }
        }

    headerEl && createApp(headerScope).mount(headerEl)
    if (!headerEl) return

    let lastScroll = 0

    const hidingOnScroll = () => {
        const currentScroll = window.scrollY;

        if (currentScroll > 320) {
            headerEl.classList.add('is-sticked')
            headerEl.classList[currentScroll > lastScroll ? 'add' : 'remove']('is-up')
        } else {
            headerEl.classList.remove('is-sticked', 'is-up')
        }

        lastScroll = currentScroll;
    }

    hidingOnScroll();
    window.addEventListener('scroll', () => hidingOnScroll(), { passive: true });
})();

window.addEventListener("create-tooltip", () => {
    document.querySelectorAll('[data-tippy-content]')
        .forEach(tooltip => {
            // @ts-ignore
            tooltip.hasOwnProperty('_tippy') && tooltip._tippy?.destroy();
        })

    tippy('[data-tippy-content]', {
        arrow: false,
    })
}, false);


(() => {
    if (!document.querySelector('.wp-block-gallery'))
        return

    new SimpleLightbox({
        elements: '.wp-block-gallery figure > a'
    })
})();

(() => {
    if (!document.querySelector('[data-gallery]'))
        return

    new SimpleLightbox({
        elements: '[data-gallery-photo] > a:not([class])'
    })

    new SimpleLightbox({
        elements: '.HomeGallery-video > a'
    })
})();

declare global {
    const archiveData: {
        apiUrl: string
        availableCount: number
        offersSum: number
        title: string
        totalCount: number
        podlazi: string
        dispozice: string
        prostory: string
        dostupnost: string
        offers: string[]
        query: Object,
        order: string
    }

    interface Window {
        initMap: () => void
    }
}


if (document.body.classList.contains('post-type-archive-offer')) {
    const Archive = reactive({
        apiUrl: archiveData?.apiUrl ?? null,
        isLoading: false,
        isQueryChanged: !!Object.keys(archiveData?.query ?? {}).length,
        availableCount: archiveData?.availableCount ?? 0,
        offersSum: archiveData?.offersSum ?? 0,
        title: archiveData?.title ?? "Nabídka bytů",
        podlazi: archiveData?.podlazi ?? "nerozhoduje",
        dispozice: archiveData?.dispozice ?? "nerozhoduje",
        prostory: archiveData?.prostory ?? "nerozhoduje",
        dostupnost: archiveData?.dostupnost ?? "nerozhoduje",
        query: archiveData?.query ?? {},
        totalCount: archiveData?.totalCount ?? 0,
        offers: archiveData?.offers ?? [],
        order: archiveData?.order ?? "",

        setFloor(floor = 0) {
            this.podlazi = floor !== 0 ? floor : "nerozhoduje"
            this.loadArchive()
        },
        toggleOrder() {
            if (this.isLoading) return

            if (this.order === "nejdrazsi") {
                this.order = "nejlevnejsi"
            } else if (this.order === "nejlevnejsi") {
                this.order = ""
            } else {
                this.order = "nejdrazsi"
            }

            this.loadArchive()
        },
        prepareQueryString() {
            const query = new URLSearchParams({
                podlazi: this.podlazi,
                dispozice: this.dispozice,
                prostory: this.prostory,
                dostupnost: this.dostupnost,
                razeni: this.order
            });

            [...query.entries()].forEach(([key, value]) =>
                (!value || value === "nerozhoduje") && query.delete(key))

            return [...query].length ? `?${decodeURIComponent(query.toString())}` : ''
        },
        async loadArchive() {
            this.isLoading = true;

            const queryString = this.prepareQueryString(),
                targetUrl = `${location.pathname}${queryString}`

            await fetch(`${this.apiUrl}${queryString}`)
                .then((response) => response.json())
                .then((data) => {
                    this.query = data.query
                    this.offers = data.offers
                    this.title = data.title
                    this.availableCount = data.availableCount
                    this.offersSum = data.offersSum
                    this.totalCount = data.totalCount

                    history.pushState({}, '', targetUrl)
                })
                .catch(() => location.assign(targetUrl))
                .finally(() => {
                    this.isLoading = false
                    window.dispatchEvent(new Event('create-tooltip'))
                })
        },
        async loadPreview() {
            this.isQueryChanged = true

            const queryString = this.prepareQueryString()
            await fetch(`${this.apiUrl}-preview${queryString}`)
                .then((response) => response.json())
                .then((data) => { this.totalCount = data.count })
        },
    })

    createApp({ Archive }).mount('[data-archive-hero]')
    createApp({ Archive }).mount('[data-offer-filter]')
    createApp({ Archive }).mount('[data-offer-list]')
}

createApp({
    closed: false,
    closeAlert(e: Event) {
        const alertEl: HTMLElement = (e.target as HTMLElement).closest('[data-key]')
        this.closed = true
        window.localStorage.setItem(`alert-${alertEl.dataset.key}-closed`, '1')
    },
    closedBefore(el: HTMLElement) {
        this.closed = !!window.localStorage.getItem(`alert-${el.dataset.key}-closed`)
    }
}).mount('[data-alert]');

document.addEventListener("DOMContentLoaded", () => {
    (() => {
        const slider = document.querySelector('[data-hero-slides]') as HTMLElement
        if (!slider) return

        new Swiper(slider, {
            slidesPerView: 1,
            allowTouchMove: false,
            cssMode: true,
            direction: "vertical",
            modules: [ Navigation, Pagination, Autoplay ],
            navigation: {
                nextEl: "[data-hero-next]",
                prevEl: "[data-hero-prev]",
            },
            pagination: {
                el: "[data-hero-pagination]",
                type: "fraction"
            },
            autoplay: {
                delay: 12000
            }
        })
    })();

    (() => {
        const dialog = document.querySelector('[data-modal-form]') as HTMLDialogElement
        if (!dialog) return

        document.querySelectorAll('[data-modal-opener]')
            .forEach((el: HTMLElement) => el.addEventListener("click", () => {
                document.body.classList.add('u-hiddenOverflow')
                dialog.showModal()
            }))

        dialog.querySelector('[data-modal-form-closer]')?.addEventListener("click", () => dialog.close())
        dialog.addEventListener("click", (ev) => (ev.target === dialog) && dialog.close())
        dialog.addEventListener("close", () => document.body.classList.remove('u-hiddenOverflow'))
    })();

    (() => {
        const modalOpener = document.querySelector('[data-modal-map-opener]') as HTMLButtonElement,
            modal = document.querySelector('[data-modal-map]') as HTMLDialogElement

        if (!modalOpener) return

        modalOpener.addEventListener('click', () => {
            if (!modal.hasAttribute('data-loaded')) {
                modal.dataset.loaded = 'true'

                const gMapsJs = document.createElement('script')
                gMapsJs.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA_IckNle5OAQHtUqaSKFQHDtGe9nmJj_0&loading=async&callback=initMap&libraries=&v=weekly`

                document.body.insertAdjacentElement('beforeend', gMapsJs)
            }

            modal.showModal()
        })

        modal?.querySelector('[data-modal-map-closer]')?.addEventListener('click', () => modal.close())
    })();

    (() => { // Count up numbers
        const animationDuration = 2400;
        const frameDuration = 1000 / 60;
        const totalFrames = Math.round( animationDuration / frameDuration );
        const easeOutQuad = t => t * ( 2 - t );

        const animateCountUp = (el: HTMLElement) => {
            if (el.classList.contains("is-counted"))
                return;

            el.classList.add("is-counted")
            el.innerHTML = el.innerHTML.replace(/([0-9]+)/g, '<span>$1</span>');
            let frame = 0;

            const number = el.querySelector('span')
            if (number.nextElementSibling?.nodeName === "SPAN") {
                number.innerHTML += number.nextElementSibling.innerHTML
                number.nextElementSibling.remove()
            }

            const countTo = parseInt( number.innerHTML, 10 );
            const counter = setInterval( () => {
                frame++;
                const progress = easeOutQuad( frame / totalFrames ),
                    currentCount = Math.round( countTo * progress );

                if ( parseInt( number.innerHTML, 10 ) !== currentCount )
                    number.innerHTML = currentCount.toLocaleString()

                frame === totalFrames && clearInterval( counter )
            }, frameDuration );
        };

        function isElementInViewport (el: HTMLElement) {
            const rect = el.getBoundingClientRect();

            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
                rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
            );
        }

        const runCountUp = () => {
            document.querySelectorAll('[data-about-number]').forEach( (el: HTMLElement) => {
                isElementInViewport(el) && animateCountUp(el);
            });
        }

        runCountUp();
        window.addEventListener("scroll", runCountUp);
    })();

    (() => {
        const btn = document.querySelector('[data-scroll-top]')
        btn?.addEventListener('click', () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }))

        const toggleButtonVisibility = () => {
            btn.toggleAttribute('disabled', window.scrollY < 480)
        }

        toggleButtonVisibility()
        window.addEventListener('scroll', () => toggleButtonVisibility(), { passive: true })
    })();

    (() => {
        const prepOfferTippyContent = (reference: HTMLElement) => {
            const data = reference.dataset.offerTippy.split(";"),
                availability = reference.dataset.availability

            let price = data[3] && data[3].length ? data[3] : 'na dotaz'
            if (!isNaN(parseInt(price))) price = parseInt(price.replaceAll(' ', '')).toLocaleString() + ' Kč'
            if (availability !== 'volny') price = availability.replace('rezervovany', 'REZERVOVANÝ').replace('prodany', 'PRODANÝ')


            return `<b>${data[0]}</b><br><small class="u-textXs">${data[1]} — ${data[2]} m<sup>2</sup></small><br><span class="tooltip-price ${availability}">${price}</span>`
        }

        tippy('[data-offer-tippy]', {
            content: (reference) => prepOfferTippyContent(reference as HTMLElement),
            arrow: false,
            allowHTML: true,
            followCursor: true,
            plugins: [followCursor]
        })
    })();

    window.dispatchEvent(new Event('create-tooltip'))
});

window.initMap = map